<template>
  <div
    class="order-status"
    :class="[statusColorClass]"
  >
    <div class="order-status__marker" />

    <p class="order-status__text">
      {{ statusText }}
    </p>
  </div>
</template>

<script>
import { defineComponent } from 'vue'

export default defineComponent({
  name: 'OrderStatus',
  props: {
    status: {
      type: Object,
      default: () => ({}),
    },
  },
  computed: {
    statusColorClass () {
      const statusColors = {
        'Назначен преподаватель': 'order-status--appointed',
        'Новый': 'order-status--new',
        'Отменен': 'order-status--canceled',
        'Закрытый': 'order-status--closed',
      };

      return statusColors[this.status?.name] || ''
    },
    statusText () {
      return this.status?.name ?? '-'
    },
  },
})
</script>

<style scoped lang="scss">
@import '@/assets/styles/colors.scss';
@import '@/widgets/order-story/styles/typography.scss';

.order-status {
  display: flex;
  align-items: center;
  gap: 4px;
  width: max-content;
  padding: 4px;
  border-radius: 4px;
  &__marker {
    flex-shrink: 0;
    width: 4px;
    height: 4px;
    border-radius: 100%;
  }
  &__text {
    font-size: 10px;
    font-weight: 500;
    line-height: 12px;
  }
  &#{&}--appointed {
    background-color: $success-100;
  }
  &#{&}--new {
    background-color: $primary-100;
  }
  &#{&}--canceled {
    background-color: $alert-100;
  }
  &#{&}--closed {
    background-color: $danger-100;
  }
  &--appointed {
    .order-status {
      &__marker {
        background-color: $success-400;
      }
      &__text {
        color: $success-600;
      }
    }
  }
  &--new {
    .order-status {
      &__marker {
        background-color: $primary-400;
      }
      &__text {
        color: $primary-800;
      }
    }
  }
  &--canceled {
    .order-status {
      &__marker {
        background-color: $alert-400;
      }
      &__text {
        color: $alert-600;
      }
    }
  }
  &--closed {
    .order-status {
      &__marker {
        background-color: $danger-400;
      }
      &__text {
        color: $danger-600;
      }
    }
  }
}
</style>
