<template>
  <div class="order-teacher">
    <div class="order-teacher__info">
      <div class="order-teacher__middlename">
        {{ middlename }}
      </div>

      <div class="order-teacher__part-name">
        {{ partName }}
      </div>
    </div>

    <div class="order-teacher__control">
      <img
        v-if="isFavoriteTeacher"
        class="order-teacher__favorite"
        src="@/widgets/order-story/icons/heart.svg"
        alt="Любимый педагог"
      >

      <button
        class="order-teacher__button"
        @click="repeatOrder"
      >
        <img
          src="@/widgets/order-story/icons/spin.svg"
          alt="Повторить заказ"
        >
      </button>

      <button
        class="order-teacher__button"
        @click="openOrder"
      >
        <img
          src="@/widgets/order-story/icons/arrow.svg"
          alt="Открыть заказ"
        >
      </button>
    </div>
  </div>
</template>

<script>
import { defineComponent } from 'vue'

export default defineComponent({
  name: 'OrderTeacher',
  props: {
    order: {
      type: Object,
      default: () => ({}),
    },
    favoriteTeachers: {
      type: Array,
      default: () => [],
    },
  },
  computed: {
    middlename () {
      return this.order?.teacher?.attributes?.middleName  ?? ''
    },
    partName () {
      const firstName = this.order?.teacher?.attributes?.firstName ?? ''
      const lastName = this.order?.teacher?.attributes?.lastName ?? ''

      return `${firstName} ${lastName}`
    },
    isFavoriteTeacher () {
      return this.favoriteTeachers
        .map((teacher) => teacher.specialistId)
        .includes(this.order?.teacher?.id)
    },
  },
  methods: {
    repeatOrder () {
      this.$router.push({
        name: 'NewOrder',
        query: {
          buyerId: this.order?.student?.id,
          durationName: this.order?.duration?.name,
          subjectId: this.order?.subject?.id,
          studentGrade: this.order?.attributes?.studentGrade,
          lessonTypeId: this.order?.attributes?.lessonType?.id,
          teachingTypeId: this.order?.teachingType?.id,
        },
      })
    },
    openOrder () {
      this.$router.push(`/orders/${this.order?.id}`)
    },
  },
})
</script>

<style scoped lang="scss">
@import '@/assets/styles/colors.scss';
@import '@/widgets/order-story/styles/typography.scss';

.order-teacher {
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 70px;
  &__middlename,
  &__part-name {
    color: $dark-400;
    @include base-text;  
  }
  &__control {
    display: flex;
    align-items: center;
    gap: 8px;
  }
  &__favorite {
    flex-shrink: 0;
    width: 12px;
    height: 12px;
  }
  &__button {
    width: max-content;
    height: 32px;
    padding: 8px;
    border-radius: 8px;
    border: none;
    outline: none;
    cursor: pointer;
    background-color: $primary-100;
    & > img {
      width: 16px;
      height: 16px;
    }
  }
}
</style>