<template>
  <slot-table
    class="orders-story"
    :headers="headers"
    :data="orders"
  >
    <template #column-0="{ row }">
      <order-id :value-id="row.id" />
    </template>

    <template #column-1="{ row }">
      <order-status :status="row.status" />
    </template>

    <template #column-2="{ row }">
      <order-date :datetime="row.datetime" />
    </template>

    <template #column-3="{ row }">
      <order-duration :order="row" />
    </template>

    <template #column-4="{ row }">
      <order-details
        :subject-details="row.subject"
        :order-attributes="row.attributes"
      />
    </template>

    <template #column-5="{ row }">
      <order-student :student="row.student" />
    </template>

    <template #column-6="{ row }">
      <order-teacher
        :order="row"
        :favorite-teachers="favoriteTeachers"
      />
    </template>
  </slot-table>
</template>

<script>
import { defineComponent } from 'vue'
import SlotTable from '@/basic/SlotTable.vue'
import OrderId from '@/widgets/order-story/components/cells/OrderId.vue'
import OrderStatus from '@/widgets/order-story/components/cells/OrderStatus.vue'
import OrderDate from '@/widgets/order-story/components/cells/OrderDate.vue'
import OrderDuration from '@/widgets/order-story/components/cells/OrderDuration.vue'
import OrderDetails from '@/widgets/order-story/components/cells/OrderDetails.vue'
import OrderStudent from '@/widgets/order-story/components/cells/OrderStudent.vue'
import OrderTeacher from '@/widgets/order-story/components/cells/OrderTeacher.vue'

export default defineComponent({
  name: 'OrderStoryTable',
  components: {
    SlotTable,
    OrderId,
    OrderStatus,
    OrderDate,
    OrderDuration,
    OrderDetails,
    OrderStudent,
    OrderTeacher,
  },
  props: {
    orders: {
      type: Array,
      default: () => [],
    },
    favoriteTeachers: {
      type: Array,
      default: () => [],
    },
  },
  data: () => ({
    headers: [
      'ID',
      'Статус',
      'Дата',
      'Время',
      'Детали',
      'Ученик',
      'Педагог'
    ],
  }),
})
</script>
