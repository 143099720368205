<template>
  <div class="order-student">
    <div class="order-student__middlename">
      {{ middlename }}
    </div>

    <div class="order-student__part-name">
      {{ partName }}
    </div>
  </div>
</template>

<script>
import { defineComponent } from 'vue'

export default defineComponent({
  name: 'OrderStudent',
  props: {
    student: {
      type: Object,
      default: () => ({}),
    },
  },
  computed: {
    middlename () {
      return this.student?.attributes?.middleName ?? ''
    },
    partName () {
      const firstName = this.student?.attributes?.firstName ?? ''
      const lastName = this.student?.attributes?.lastName ?? ''

      return `${firstName} ${lastName}`
    }
  },
})
</script>

<style scoped lang="scss">
@import '@/assets/styles/colors.scss';
@import '@/widgets/order-story/styles/typography.scss';

.order-student {
  &__middlename,
  &__part-name {
    color: $dark-400;
    @include base-text;  
  }
}
</style>
