<template>
  <div v-if="loading" :class="$style.parentDetails__loading">
    <Loading />
  </div>

  <div v-else :class="$style.parentDetails">
    <div :class="$style.parentDetails__top">
      <Title
        :class="$style.parentDetails__title"
        text="Детали родителя"
        position="left"
      />

      <div :class="$style.parentDetails__create_orders_buttons">
        <Button
          type="tertiary"
          @click="goToCreateOrder(false)"
        >
          Создать заказ
        </Button>

        <Button
          type="tertiary"
          @click="goToCreateOrder(true)"
        >
          Создать пробное
        </Button>
      </div>
    </div>

    <div v-if="Object.keys(parentDetails).length" :class="$style.parentDetails__content">
      <h2 :class="$style.parentDetails__content_title">Личные данные</h2>

      <div v-if="isAdmin || isManager" :class="$style.parentDetails__content_wrap">
        <div :class="$style.parentDetails__content_input">
          <p :class="$style.parentDetails__content_text">Фамилия*</p>
          <Input
            v-model="parentAttributes.lastName"
            :class="$style.parentDetails__input"
            placeholder="Не заполнено"
            :error="errors.lastName && !firstFill"
            @input="setText('lastName')"
          />
        </div>
        <div :class="$style.parentDetails__content_input">
          <p :class="$style.parentDetails__content_text">Имя*</p>
          <Input
            v-model="parentAttributes.firstName"
            :class="$style.parentDetails__input"
            placeholder="Не заполнено"
            :error="errors.firstName && !firstFill"
            @input="setText('firstName')"
          />
        </div>
        <div :class="$style.parentDetails__content_input">
          <p :class="$style.parentDetails__content_text">Отчество</p>
          <Input
            v-model="parentAttributes.middleName"
            placeholder="Не заполнено"
            :class="$style.parentDetails__input"
          />
        </div>
        <div :class="$style.parentDetails__content_input">
          <p :class="$style.parentDetails__content_text">Год рождения</p>
          <date-picker
            v-model="parentBithday"
            :class="$style.parentDetails__input"
            :lang="lang"
            type="date"
            :editable="false"
            placeholder="Выберите дату"
            :formatter="momentFormat"
          />
        </div>
        <div :class="$style.parentDetails__content_input">
          <p :class="$style.parentDetails__content_text">Пол</p>
          <Select
            v-model="parentAttributes.genderId"
            style="width: 10rem"
            placeholder="Не заполнено"
            :items="genders"
            @input="(val) => (parentAttributes.genderId = val?.id ?? val)"
          />
        </div>
        <div :class="$style.parentDetails__content_input">
          <p :class="$style.parentDetails__content_text">Регион*</p>
          <div :class="$style.parentDetails__wrapper">
            <Input
              v-model="locality.region"
              :class="$style.parentDetails__address"
              type="secondary"
              placeholder="Выберите из списка*"
              :autocomplete="false"
              :error="errors.region && !firstFill"
              @input="getRegions(locality.region)"
              @enter="setRegion(locality.region)"
            />
            <transition name="select">
              <div v-if="regions.length > 0" :class="$style.parentDetails__dropdown">
                <p
                  v-for="(r, i) in regions"
                  :key="i"
                  :class="$style.parentDetails__region"
                  @click="setRegion(r.region)"
                >
                  {{ r.region }}
                </p>
              </div>
            </transition>

            <div
              v-if="regions.length > 0"
              :class="$style.parentDetails__close"
              @click="resetRegions"
            />
          </div>
        </div>
        <div :class="$style.parentDetails__content_info">
          <p :class="$style.parentDetails__content_text">Телефон*</p>
          <div>
            <span>{{ setValueInField(parentDetails.phone) }}</span>
          </div>
        </div>
        <div :class="$style.parentDetails__content_input">
          <p :class="$style.parentDetails__content_text">Дополнительный телефон</p>
          <Input
            :class="$style.parentDetails__input"
            v-model="parentReservedPhone"
            :mask="'7(###) ###-##-##'"
            placeholder="7(___) ___-__-__"
            :error="errors.phone && !firstFill"
            @input="(val) => setPhone(val)"
          />
        </div>
        <div :class="$style.parentDetails__content_input">
          <p :class="$style.parentDetails__content_text">Почта*</p>
          <Input
            v-model="parentDetails.email"
            :class="$style.parentDetails__input"
            placeholder="________@____.__*"
            :error="errors.email && !firstFill"
            @input="setEmail"
          />
        </div>
        <div :class="$style.parentDetails__content_info">
          <p :class="$style.parentDetails__content_text">Статус</p>
          <div>
            <span>{{ parentDetails.isVerified ? 'Верифицирован' : 'Не верифицирован' }}</span>
          </div>
        </div>
        <div v-if="!parentDetails.hasPinCode" :class="$style.parentDetails__pin">
          <Input
            v-model="pinCode"
            :class="$style.parentDetails__input"
            :mask="'####'"
            placeholder="PIN"
            @input="(value) => (!value ? (pinCode = null) : true)"
          />
          <img src="../assets/icons/save.svg" @click="savePinCode" />
        </div>
      </div>

      <div v-else :class="$style.parentDetails__content_wrap">
        <div :class="$style.parentDetails__content_info">
          <p>Фамилия</p>
          <div>
            <span>{{ setValueInField(parentAttributes.lastName) }}</span>
          </div>
        </div>
        <div :class="$style.parentDetails__content_info">
          <p>Имя</p>
          <div>
            <span>{{ setValueInField(parentAttributes.firstName) }}</span>
          </div>
        </div>
        <div :class="$style.parentDetails__content_info">
          <p>Отчество</p>
          <div>
            <span>{{ setValueInField(parentAttributes.middleName) }}</span>
          </div>
        </div>
        <div :class="$style.parentDetails__content_info">
          <p>Год рождения</p>
          <div>
            <span>{{ setValueInField(parentAttributes.birthday) }}</span>
          </div>
        </div>
        <div :class="$style.parentDetails__content_info">
          <p>Пол</p>
          <div>
            <span>{{ setValueInField(parentAttributes.gender) }}</span>
          </div>
        </div>
        <div :class="$style.parentDetails__content_info">
          <p>Регион</p>
          <div>
            <span>{{ setValueInField(parentAttributes.region) }}</span>
          </div>
        </div>
        <div :class="$style.parentDetails__content_info">
          <p>Телефон</p>
          <div>
            <span>{{ setValueInField(parentDetails.phone) }}</span>
          </div>
        </div>
        <div :class="$style.parentDetails__content_info">
          <p>Дополнительный телефон</p>
          <div>
            <span>{{ setValueInField(parentDetails.reservedPhone) }}</span>
          </div>
        </div>
        <div :class="$style.parentDetails__content_info">
          <p>Почта</p>
          <div>
            <span>{{ setValueInField(parentDetails.email) }}</span>
          </div>
        </div>
        <div :class="$style.parentDetails__content_info">
          <p>Статус</p>
          <div>
            <span>{{ parentDetails.isVerified ? 'Верифицирован' : 'Не верифицирован' }}</span>
          </div>
        </div>
        <div v-if="!parentDetails.hasPinCode" :class="$style.parentDetails__pin">
          <Input
            v-model="pinCode"
            :class="$style.newParent__input"
            :mask="'####'"
            placeholder="PIN"
            @input="(value) => (!value ? (pinCode = null) : true)"
          />
          <img src="../assets/icons/save.svg" @click="savePinCode" />
        </div>
      </div>

      <div v-if="isAdmin || isManager">
        <h2 :class="$style.parentDetails__content_title">Изменение пользователя</h2>
        <div style="display: flex">
          <div>
            <div v-if="parentLastUpdate" :class="$style.parentDetails__content_info">
              <p :class="$style.parentDetails__content_text">Дата последнего изменения</p>
              <div>
                <span>{{
                  parentLastUpdate
                    ? moment(parentLastUpdate.createdAt).format('DD-MM-YYYY - HH:mm')
                    : 'Не заполнено'
                }}</span>
              </div>
            </div>
            <div v-if="parentLastUpdate" :class="$style.parentDetails__content_info">
              <p :class="$style.parentDetails__content_text">Кем было сделано</p>
              <div v-if="parentLastUpdate?.updatedByEmployee">
                <span>{{ setEmployeeInfo(parentLastUpdate.employee) }}</span>
              </div>
              <div v-if="parentLastUpdate?.updatedByEmployee">
                <span>{{
                  `${parentLastUpdate.employee.lastName} ${
                    parentLastUpdate.employee.firstName
                  } ${parentLastUpdate.employee.middleName ?? ''}`
                }}</span>
              </div>
              <div v-if="parentLastUpdate?.user">
                <span>{{ `Родитель id: ${parentLastUpdate.user.id}` }}</span>
              </div>
              <div v-if="parentLastUpdate?.user">
                <span>{{
                  `${parentLastUpdate.user.lastName} ${parentLastUpdate.user.firstName} ${
                    parentLastUpdate.user.middleName ?? ''
                  }`
                }}</span>
              </div>
            </div>
            <Button type="tertiary" @click="onUpdateParent">Сохранить</Button>
          </div>
          <div v-if="parentLastUpdate" :class="$style.parentDetails__content_info">
            <p :class="$style.parentDetails__content_text">Что было изменено</p>
            <div
              v-for="(actual, i) in parentLastUpdateInfo(parentLastUpdate?.actual)"
              :key="actual + i"
            >
              <span v-if="[1, 2].includes(actual)">{{
                genders.find((g) => g.id === actual)?.name ?? 'Не заполнено'
              }}</span>
              <span v-else>{{ setValueInField(actual) }}</span>
            </div>
          </div>
          <div v-if="parentLastUpdate" :class="$style.parentDetails__content_info">
            <p :class="$style.parentDetails__content_text">Предыдущее значение</p>
            <div
              v-for="(previous, i) in parentLastUpdateInfo(parentLastUpdate?.previous)"
              :key="previous + i"
            >
              <span v-if="[1, 2].includes(previous)">{{
                genders.find((g) => g.id === previous)?.name ?? 'Не заполнено'
              }}</span>
              <span v-else>{{ setValueInField(previous) }}</span>
            </div>
          </div>
        </div>
      </div>

      <h2 v-if="children.length" :class="$style.parentDetails__content_title">Ученики</h2>

      <div v-if="children.length" :class="$style.parentDetails__content_wrap">
        <div
          v-for="child in children"
          :key="child.id"
          :class="$style.parentDetails__content_child"
        >
          <div :class="$style.parentDetails__content_info">
            <p>ID</p>
            <div>
              <span>{{ setValueInField(child.id) }}</span>
            </div>
          </div>

          <div :class="$style.parentDetails__content_info">
            <p>Фамилия</p>
            <div>
              <span>{{ setValueInField(child.attributes.lastName) }}</span>
            </div>
          </div>

          <div :class="$style.parentDetails__content_info">
            <p>Имя</p>
            <div>
              <span>{{ setValueInField(child.attributes.firstName) }}</span>
            </div>
          </div>

          <div :class="$style.parentDetails__content_info">
            <p>Отчество</p>
            <div>
              <span>{{ setValueInField(child.attributes.middleName) }}</span>
            </div>
          </div>

          <div :class="$style.parentDetails__content_info">
            <p>Год рождения</p>
            <div>
              <span>{{
                setValueInField(moment(child.attributes.birthday).format('DD-MM-YYYY'))
              }}</span>
            </div>
          </div>
        </div>
      </div>

      <h2 :class="$style.parentDetails__content_title">Информация по тарифу</h2>

      <div :class="$style.parentDetails__content_wrap">
        <div :class="$style.parentDetails__content_info">
          <p>Остаток предметных минут</p>
          <div>
            <span>{{ parentDetails.account.minutes }}</span>
          </div>
        </div>

        <div :class="$style.parentDetails__content_info">
          <p>Остаток логопедических минут</p>
          <div>
            <span>{{ parentDetails.account.logopedisticMinutes }}</span>
          </div>
        </div>
      </div>

      <h2 :class="$style.parentDetails__content_title">Добавление минут</h2>

      <div v-if="!isTechnicalSupport" :class="$style.parentDetails__content_wrap">
        <div :class="$style.parentDetails__content_input">
          <p :class="$style.parentDetails__content_text">Предметные минуты</p>
          <InputNumber
            v-model="minutes"
            :maxlength="5"
            style="border-color: #e4e4e4"
            @input="(value)=> setMinutes(value, 'minutes')"
          />
        </div>
        <div :class="$style.parentDetails__content_input">
          <p :class="$style.parentDetails__content_text">Логопедические минуты</p>
          <InputNumber
            v-model="logopedisticMinutes"
            :maxlength="5"
            style="border-color: #e4e4e4"
            @input="(value)=> setMinutes(value, 'logopedisticMinutes')"
          />
        </div>
        <div style="display: flex;margin: auto 0;">
          <Button type="tertiary" @click="addMinutes">Добавить</Button>
        </div>
      </div>

      <div v-if="transactions.length" :class="$style.parentDetails__content_info">
        <p>Все покупки {{ transactions.length }}</p>

        <transition-group
          :style="{
            height: transactions.length > 5 ? 4.25 * offset + 'rem' : 'auto',
            overflow: 'hidden'
          }"
          :class="$style.parentDetails__content_wrap"
          name="transactions"
          tag="div"
        >
          <span
            v-for="transaction in transactions.filter((e) => e.typeId === 1).slice(0, offset)"
            :key="transaction.id"
            :class="$style.parentDetails__content_transaction"
          >
            <div
              :class="[
                $style.parentDetails__content_info,
                $style.parentDetails__content_info_id
              ]"
            >
              <p>ID</p>

              <span>{{ transaction.id }}</span>
            </div>

            <div :class="$style.parentDetails__content_info">
              <p>Дата</p>

              <span>{{ moment(transaction.createdAt).format('DD MMMM YYYY HH:mm') }}</span>
            </div>

            <div :class="$style.parentDetails__content_info">
              <p>Длительность</p>

              <span>{{ setPluralizeMinutes(transaction.minutes) }}</span>
            </div>

            <div
              :class="[
                $style.parentDetails__content_info,
                $style.parentDetails__content_info_duration
              ]"
            >
              <p>Цена</p>

              <span>{{ transaction.price }} руб.</span>
            </div>

            <div
              :class="[
                $style.parentDetails__content_info,
                $style.parentDetails__content_info_paid
              ]"
            >
              <p>Оплачено</p>

              <span>{{ transaction.paid ? 'Да' : 'Нет' }}</span>
            </div>
          </span>
        </transition-group>

        <img
          v-if="transactions.length > 5"
          :class="[
            $style.parentDetails__content_info_arrow,
            { [$style.parentDetails__content_info_arrow_active]: endTransactions }
          ]"
          src="@/assets/icons/select-arrow.svg"
          alt="arrow"
          @click="showMore"
        />
      </div>
    </div>

    <order-story
      :parent-id="this.parentDetails?.id"
      :favorite-teachers="parentDetails?.favouriteSpecialists ?? []"
    />
  </div>
</template>

<script>
import Moment from 'moment'
import Title from '@/basic/Title'
import Input from '@/basic/Input'
import Select from '@/basic/DefaultSelect'
import Loading from '@/basic/Loading'
import Button from '@/basic/Button'
import { OrderStory } from '@/widgets/order-story'

import DatePicker from 'vue2-datepicker'
import ru from 'vue2-datepicker/locale/ru'
import 'vue2-datepicker/index.css'

import userApi from '../api/users'
import specialistsApi from '../api/specialists'
import dadataApi from '../api/dadata'

import { mapActions, mapGetters, mapMutations } from 'vuex'
import { pluralize } from '@/utils/string'
import { EmployeeNames } from '@/application/constants'
import InputNumber from '@/basic/InputNumber.vue'

const orderTypes = [
  {
    id: 1,
    name: 'Срочный'
  },
  {
    id: 2,
    name: 'Отложенный'
  },
  {
    id: 3,
    name: 'Переодический'
  }
]

const genders = [
  {
    id: 1,
    name: 'Мужской'
  },
  {
    id: 2,
    name: 'Женский'
  }
]

export default {
  components: {
    OrderStory,
    InputNumber,
    Title,
    Input,
    Loading,
    DatePicker,
    Select,
    Button
  },
  data() {
    return {
      lang: ru,
      loading: false,
      logopedisticMinutes: null,
      minutes: null,
      pinCode: null,

      offset: 5,
      endTransactions: false,

      ordersOffset: 10,
      endOrders: false,

      orderTypes,
      genders,

      momentFormat: {
        stringify: (date) => {
          return date ? Moment(date).format('DD-MM-YYYY') : ''
        }
      },

      parentReservedPhone: '',
      parentBithday: '',

      regions: [],
      locality: {
        region: '',
        latitude: null,
        longitude: null,
        timezone: null
      },
      errors: {
        firstName: false,
        lastName: false,
        phone: false,
        email: false,
        region: false
      },
      firstFill: true
    }
  },
  computed: {
    ...mapGetters(['parentDetails', 'parentOrders', 'isAdmin', 'isManager', 'isTechnicalSupport']),

    moment() {
      return Moment
    },

    parentId() {
      return this.parentDetails?.id ?? this.$route.params.id
    },

    parentAttributes() {
      return this.parentDetails?.attributes ?? null
    },

    children() {
      return this.parentDetails?.children
    },

    transactions() {
      return [...this.parentDetails?.transactions].sort((a, b) => b.id - a.id)
    },

    parentLastUpdate() {
      return this.parentDetails?.lastUpdate ?? null
    }
  },
  async created() {
    try {
      this.loading = true

      if (Object.keys(this.parentDetails).length === 0) {
        await this.getParent(this.parentId)
      }

      if (this.parentAttributes?.region) {
        this.locality.region = this.parentAttributes.region
        this.locality.latitude = this.parentAttributes.latitude
        this.locality.longitude = this.parentAttributes.longitude
        this.locality.timezone = this.parentAttributes.timezone
      }

      if (this.parentAttributes?.birthday) {
        this.parentBithday = Moment(this.parentAttributes.birthday).toDate()
      }

      if (this.parentDetails?.reservedPhone) {
        this.parentReservedPhone = `${this.parentDetails.reservedPhone}`
      }

      if (this.parentDetails?.id) {
        await this.getParentOrders({ filter: { customerId: this.parentDetails.id } })
      }
    } finally {
      this.loading = false
    }
  },
  beforeDestroy() {
    this.setParentDetails({})
  },
  methods: {
    ...mapActions(['getParent', 'getParentOrders']),
    ...mapMutations(['setParentDetails']),

    async getInformation() {
      try {
        this.loading = true
        await this.getParent(this.parentId)
        await this.getParentOrders({ filter: { customerId: this.parentDetails.id } })
      } finally {
        this.loading = false
      }
    },

    goToCreateOrder(isTrialLesson = false) {
      this.$router.push({
        name: 'NewOrder',
        query: {
          buyerId: this.parentId,
          isTrial: isTrialLesson,
        }
      })
    },

    setValueInField(value) {
      return value ? value : 'Не заполнено'
    },
    async addFavouriteSpecialist(specialistId) {
      await specialistsApi.addFavouriteSpecialist({
        userId: this.parentDetails?.id,
        specialistId
      })
      await this.getInformation()
    },
    async removeFavouriteSpecialist(specialistId) {
      await specialistsApi.removeFavouriteSpecialist({
        userId: this.parentDetails?.id,
        specialistId
      })
      await this.getInformation()
    },

    isFavouriteSpecialist(id) {
      return this.parentDetails?.favouriteSpecialists.map((e) => e.specialistId).includes(id)
    },

    setPluralizeMinutes(value) {
      return `${value} ${pluralize(value, 'минута', 'минуты', 'минут')}`
    },

    showMore() {
      if (this.transactions.length > this.offset) {
        const num = this.transactions.length - this.offset
        this.offset += num > 5 ? 5 : num
        if (this.transactions.length <= this.offset && !this.endTransactions) {
          this.endTransactions = true
        }
      } else {
        this.offset = 5
        this.endTransactions = false
      }
    },

    showMoreOrders() {
      if (this.parentOrders.rows.length > this.ordersOffset) {
        const num = this.parentOrders.rows.length - this.ordersOffset
        this.ordersOffset += num > 10 ? 10 : num
        if (this.parentOrders.rows.length <= this.ordersOffset && !this.endOrders) {
          this.endOrders = true
        }
      } else {
        this.ordersOffset = 10
        this.endOrders = false
      }
    },
    getTime(seconds = 0, order) {
      if (!seconds && order?.chat?.end) {
        seconds = Moment(order?.chat?.end).diff(Moment(order?.chat?.start), 's')
      }
      return Moment.utc(seconds * 1000).format('HH:mm:ss')
    },
    async savePinCode() {
      await userApi.setPinCode({
        id: this.parentId,
        pinCode: this.pinCode
      })
      await this.getInformation()
    },

    async getRegions(query) {
      this.errors.region = true

      if (query.length >= 2 && query.length % 3 === 0) {
        try {
          const { data } = await dadataApi.getAddressSuggestions(query, 5)

          if (data?.success) {
            this.regions = data.result
          }
        } catch (error) {
          console.warn(error)
          await this.$store.dispatch('openModal', [
            'Alert',
            {
              title: 'Ошибка',
              message: 'Ошибка получения предложения адресов с сервера!'
            }
          ])
        }
      } else {
        this.regions = []
      }
    },
    resetRegions() {
      this.regions = []
    },
    async setRegion(region) {
      this.regions = []

      try {
        const { data } = await dadataApi.getAddressSuggestions(region, 1)

        if (data?.success && data?.result[0]?.timezone) {
          this.locality = {
            region,
            latitude: data.result[0].latitude ?? 0,
            longitude: data.result[0].longitude ?? 0,
            timezone: +/\d+/.exec(data.result[0].timezone)
          }
          this.errors.region = false
        } else {
          await this.$store.dispatch('openModal', [
            'Alert',
            {
              title: 'Ошибка',
              message:
                'По данному населённому пункту нет дополнительной информации, пожалуйста, выберите ближайший крупный город.'
            }
          ])
        }
      } catch (error) {
        console.warn(error)
        await this.$store.dispatch('openModal', [
          'Alert',
          {
            title: 'Ошибка',
            message: 'Ошибка получения информации по адресу с сервера!'
          }
        ])
      }
    },
    hasValidateErorr() {
      for (const key in this.errors) {
        if (this.errors[key]) {
          return true
        }
      }
    },
    setMinutes(value, type) {
      if (value.length === 0) {
        this[type] = null
      }
    },
    setText(text) {
      this.errors[text] = this.parentAttributes[text]?.length < 2;
    },
    async addMinutes() {

      try {
        this.loading = true
        const data = { userId: this.parentId }
        if (this.minutes === '0' && this.logopedisticMinutes === '0') return
        if (this.minutes) {
          data.minutes = +this.minutes
        }
        if (this.logopedisticMinutes) {
          data.logopedisticMinutes = +this.logopedisticMinutes
        }
        const { success } = await userApi.addMinutes(data)
        if (success) {
          this.minutes = null
          this.logopedisticMinutes = null
          const user = await this.getParent(this.parentDetails.id)
          this.setParentDetails({ ...user })
        }
      } catch (error) {
        console.warn(error)
        await this.$store.dispatch('openModal', [
          'Alert',
          {
            title: 'Ошибка',
            message: 'Ошибка добавление минут родителя на сервере!'
          }
        ])
      } finally {
        this.loading = false
      }
    },
    setPhone(phone) {
      this.errors.phone = ![0, 11].includes(phone?.replace(/\D/g, '').length);
    },
    setEmail() {
      this.errors.email = !/.+@.+\.[A-Za-z]+$/.test(this.parentDetails.email);
    },
    async onUpdateParent() {
      if (this.hasValidateErorr()) {
        await this.$store.dispatch('openModal', [
          'Alert',
          { title: 'Ошибка!', message: 'Заполните все обязательные поля!' }
        ])
        this.firstFill = false
        return
      }

      try {
        this.loading = true

        const { data } = await userApi.updateUser(this.parentDetails.id, {
          attributes: {
            firstName: this.parentAttributes.firstName,
            middleName: this.parentAttributes.middleName,
            lastName: this.parentAttributes.lastName,
            birthday: this.parentBithday
              ? Moment(this.parentBithday).format('YYYY-MM-DD')
              : null,
            genderId: this.parentAttributes.genderId,
            ...this.locality
          },
          email: this.parentDetails.email,
          reservedPhone: this.parentReservedPhone?.replace(/\D/g, '') || null
        })
        if (data?.success && data?.result?.lastUpdate) {
          const user = await this.getParent(this.parentDetails.id)
          this.setParentDetails({ ...user, lastUpdate: data.result.lastUpdate })
        }
      } catch (error) {
        console.warn(error)
        await this.$store.dispatch('openModal', [
          'Alert',
          {
            title: 'Ошибка',
            message: 'Ошибка обновления информации родителя на сервере!'
          }
        ])
      } finally {
        this.loading = false
      }
    },
    setEmployeeInfo(employee) {
      const roleName = EmployeeNames.find((e) => e.id === employee.roleId)?.name ?? ''
      return `${roleName} id: ${employee.id}`
    },
    parentLastUpdateInfo(obj) {
      const arr = []
      for (const key in obj) {
        if (key === 'attributes') {
          for (const attr in obj[key]) {
            arr.push(obj[key][attr])
          }
        } else {
          arr.push(obj[key])
        }
      }
      return arr
    }
  }
}
</script>

<style lang="scss" module>
@import '@/assets/styles/colors.scss';

.parentDetails {
  &__top {
    display: flex;
    justify-content: space-between;
  }
  &__title {
    margin: 0;
  }
  &__create_orders_buttons {
    display: flex;
    gap: 24px;
  }
  &__address {
    width: 100%;
  }
  &__wrapper {
    position: relative;
    width: 25rem;
  }
  &__dropdown {
    overflow: hidden;
    position: absolute;
    z-index: 99;
    left: 0;
    right: 0;
    top: 2.3rem;
    background: $white;
    border: 1px solid $platinum;
    color: $wet-asphalt;
  }
  &__region {
    padding: 0.5rem 0.625rem;
    border-bottom: 1px solid $gainsborough;
    font-size: 14px;
    cursor: pointer;
    transition: background-color 0.3s ease;

    &:hover {
      background-color: $green-tea;
    }

    &:last-child {
      border-bottom: none;
    }
  }
  &__close {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 80;
  }

  &__pin {
    display: flex;
    div {
      margin-left: 0;
    }
    input {
      &:first-child {
        min-width: 17.188rem;
        height: 32px;
        border: 1px solid #c4c4c4;
      }
    }
    img {
      cursor: pointer;
      margin-left: 5px;
    }
  }
  &__loading {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    min-height: 90vh;
  }

  &__content {
    &_title {
      margin: 2rem 0 1rem;
      font-size: 1.25rem;
      font-weight: 500;
    }

    &_wrap {
      display: flex;
      flex-wrap: wrap;
      align-items: flex-start;
      transition: height 0.3s ease;
    }

    &_info {
      position: relative;
      min-width: 17.188rem;
      width: auto;
      margin-right: 0.625rem;
      margin-bottom: 0.938rem;

      &_heart {
        height: 1rem;
        margin: 0 0 0 0.25rem;
        cursor: pointer;
      }

      &_id {
        display: flex;
        justify-content: center;
        min-width: 3rem;
      }

      &_paid {
        display: flex;
        justify-content: center;
        min-width: 5rem;
      }

      &_type {
        min-width: 5rem;
      }

      &_date {
        min-width: 9rem;
      }

      &_duration {
        min-width: 12rem;
      }

      &_status {
        min-width: 5rem;
      }

      &_pedagog {
        display: flex;
        justify-content: center;
        min-width: 4.5rem;
      }

      &_subject {
        min-width: 10rem;
      }

      p {
        margin: 0 0 0.15rem 0.35rem;
        background: $white;
        font-size: 0.7rem;
        color: $gray;
      }

      div {
        position: relative;
        border: 1px solid $input-border;
        border-radius: 0.25rem;
        padding: 0.5rem 0.75rem;
        font-size: 14px;
      }

      &_arrow {
        position: absolute;
        bottom: -1.5rem;
        left: 50%;
        right: 50%;
        padding: 0.5rem;
        cursor: pointer;
        transition: all 0.3s ease;

        &_active {
          transform: rotate(180deg);
        }
      }
    }

    &_input {
      margin: 0 0.625rem 0.938rem 0;
    }

    &_text {
      margin: 0 0 0.15rem 0.35rem;
      font-size: 0.7rem;
      color: $gray;
    }

    &_transaction {
      display: flex;
      justify-content: space-between;
      width: 100%;
      padding: 0.938rem 0 0;
      border-bottom: 1px solid $medium-gray;

      &:last-child {
        border: none;
      }

      p {
        position: absolute;
        top: -0.5rem;
        left: 0;
        z-index: 5;
      }
    }

    &_orders {
      position: relative;
      margin: 0 0 2rem;
      p {
        position: absolute;
        top: -0.5rem;
        left: 0;
        z-index: 5;
        margin: 0 0 0.15rem 0.35rem;
        background: $white;
        font-size: 0.7rem;
        color: $gray;
      }

      div {
        position: relative;
        border: 1px solid $input-border;
        border-radius: 0.25rem;
        padding: 0.5rem 0.75rem;
        font-size: 14px;
      }

      @media only screen and (max-width: 1473px) {
        span {
          font-size: 0.65rem;
        }
      }
    }
  }
}
</style>
