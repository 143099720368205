<template>
  <div class="order-date">
    <p class="order-date__year">{{ date }}</p>
    <p class="order-date__time">{{ time }}</p>
  </div>
</template>

<script>
import { defineComponent } from 'vue'
import Moment from 'moment'

export default defineComponent({
  name: 'OrderDate',
  props: {
    datetime: {
      type: String,
      default: '',
    },
  },
  computed: {
    date() {
      if (!this.datetime) {
        return '-'
      }

      return Moment(this.datetime).format('DD.MM.YYYY')
    },
    time() {
      if (!this.datetime) {
        return '-'
      }

      return Moment(this.datetime).format('HH:mm')
    },
  },
})
</script>

<style scoped lang="scss">
@import '@/assets/styles/colors.scss';
@import '@/widgets/order-story/styles/typography.scss';

.order-date {
  &__year,
  &__time {
    color: $dark-400;
    @include base-text;
  }
}
</style>
