<template>
  <table class="slot-table">
    <thead class="slot-table__head">
      <tr class="slot-table__head-row">
        <th
          v-for="header in headers"
          :key="header"
          class="slot-table__head-cell"
        >
          {{ header }}
        </th>
      </tr>
    </thead>

    <tbody class="slot-table__body">
      <tr
        v-for="(row, rowIndex) in data"
        :key="rowIndex"
        class="slot-table__row"
      >
        <td
          v-for="(column, colIndex) in headers"
          :key="colIndex"
          class="slot-table__body-cell"
        >
          <slot
            :name="`column-${colIndex}`"
            :row="row"
            :value="row[column]"
          />
        </td>
      </tr>
    </tbody>
  </table>
</template>

<script>
import { defineComponent } from 'vue'

export default defineComponent({
  name: 'SlotTable',
  props: {
    headers: {
      type: Array,
      required: true,
    },
    data: {
      type: Array,
      required: true,
    },
  },
})
</script>

<style scoped lang="scss">
@import '@/assets/styles/colors.scss';

.slot-table {
  width: 100%;
  border-collapse: collapse;
  &__head-row {
    border-bottom: 1px solid $smoky-white;
  }
  &__row {
    border-bottom: 1px solid $smoky-white;
    transition: .2s all ease-in-out;
    &:hover {
      background-color: rgba(248, 248, 248, .5);
    }
  }
  &__head-cell,
  &__body-cell {
    text-align: left;
    padding: 16px 8px;
  }
  &__head-cell {
    font-size: 12px;
    font-weight: 600;
    line-height: 16px;
    color: #262626;
  }
}
</style>