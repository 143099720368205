<template>
  <div class="order-story">
    <p class="order-story__title">
      Заказы
    </p>

    <div class="order-story__filtering">
      <date-picker
        v-model="filters.datetime"
        class="order-story__date-picker"
        range
        format="YYYY-MM-DD"
        placeholder="Дата"
        valueType="format"
        :lang="datePickerLang"
      />

      <div class="order-story__filtering-right">
        <default-select
          v-model="filters.status.id"
          class="order-story__filter"
          placeholder="Статус"
          :items="avaibleItemsToFilter.statuses"
          @input="(choosenItem) => changeStatusFilter(choosenItem)"
        />

        <default-select
          v-model="filters.teacher.id"
          class="order-story__filter"
          placeholder="Педагог"
          :items="avaibleItemsToFilter.teachers"
          @input="(choosenItem) => changeTeacherFilter(choosenItem)"
        />

        <default-select
          v-model="filters.subject.id"
          class="order-story__filter"
          placeholder="Предмет"
          :items="avaibleItemsToFilter.subjects"
          @input="(choosenItem) => changeSubjectFilter(choosenItem)"
        />
      </div>
    </div>

    <order-story-table
      :orders="visibleOrders"
      :favorite-teachers="favoriteTeachers"
    />

    <div class="order-story__control">
      <pagination
        v-if="pagination.isPaginationVisible"
        :page-count="pagination.amountOfPages"
        :initial-page="currentPage"
        :click-handler="pageChange"
        class="order-story__pagination"
        next-text=""
        prev-text=""
      />

      <div class="order-story__limit">
        <p class="order-story__limit-text">
          Выводить:
        </p>

        <default-select
          v-model="limit"
          class="order-story__filter-small"
          :items="[
            { id: 5, name: 'По 5 записей', },
            { id: 10, name: 'По 10 записей', },
            { id: 15, name: 'По 15 записей', },
            { id: 20, name: 'По 20 записей', },
          ]"
          @input="(choosenItem) => changeLimit(choosenItem)"
        />
      </div>
    </div>
  </div>
</template>

<script>
import Moment from 'moment'

import { defineComponent } from 'vue'
import { mapActions, mapGetters, } from 'vuex'

import OrderStoryTable from '@/widgets/order-story/components/OrderStoryTable.vue';
import DefaultSelect from '@/basic/DefaultSelect'
import DatePicker from 'vue2-datepicker'

import ru from 'vue2-datepicker/locale/ru'
import 'vue2-datepicker/index.css'

export default defineComponent({
  name: 'OrderStory',
  components: {
    OrderStoryTable,
    DefaultSelect,
    DatePicker,
  },
  props: {
    parentId: {
      type: Number,
      default: 0,
    },
    favoriteTeachers: {
      type: Array,
      default: () => [],
    },
  },
  data: () => ({
    datePickerLang: ru,
    currentPage: 0,
    limit: 5,
    filters: {
      status: {
        id: null,
        name: '',
      },
      teacher: {
        id: null,
        name: '',
      },
      subject: {
        id: null,
        name: '',
      },
      datetime: '',
    },
  }),
  computed: {
    ...mapGetters(['parentOrders']),
    avaibleItemsToFilter () {
      const addedStatuses = new Set()
      const addedTeachers = new Set()
      const addedSubjects = new Set()

      return this.parentOrders.rows
        .reduce((acc, order) => {
          if (order.status && !addedStatuses.has(order.status.name)) {
            addedStatuses.add(order.status.name)
            acc.statuses.push(order.status)
          }

          if (order.subject && !addedSubjects.has(order.subject.name)) {
            addedSubjects.add(order.subject.name)
            acc.subjects.push(order.subject)
          }

          if (order.teacher?.attributes) {
            const teacher = order.teacher
            const firstName = teacher.attributes.firstName || ''
            const middleName = teacher.attributes.middleName || ''
            const lastName = teacher.attributes.lastName || ''

            const teacherId = teacher.attributes.id || 0
            const teacherFullName = `[${teacherId}] ${middleName} ${firstName} ${lastName}`

            if (!addedTeachers.has(teacherFullName)) {
              addedTeachers.add(teacherFullName)
              acc.teachers.push({
                id: teacherId,
                name: teacherFullName,
              })
            }
          }

          return acc
        },
        { statuses: [], teachers: [], subjects: [], }
      )
    },
    pagination () {
      return {
        isPaginationVisible: this.filtredOrders.length > this.limit,
        amountOfPages: Math.ceil(this.filtredOrders.length / this.limit)
      }
    },
    filtredOrders () {
      return this.parentOrders.rows
        .filter((order) => {
          if (!this.filters.status.id) {
            return true
          }

          return order.status?.name.includes(this.filters.status.name)
        })
        .filter((order) => {
          if (!this.filters.teacher.id) {
            return true
          }

          return order.teacher?.attributes?.id === this.filters.teacher.id
        })
        .filter((order) => {
          if (!this.filters.subject.id) {
            return true
          }

          return order.subject?.name.includes(this.filters.subject.name)
        })
        .filter((order) => {
          if (
            !this.filters.datetime ||
            !this.filters.datetime[0] ||
            !this.filters.datetime[1]
          ) {
            return true
          }

          const orderDate = Moment(order.datetime)
          const isOrderDateAfterFilterDate = orderDate.isAfter(
            this.filters.datetime[0],
            'day'
          )
          const isOrderDateBeforeFilterDate = orderDate.isBefore(
            this.filters.datetime[1],
            'day'
          )

          return isOrderDateAfterFilterDate && isOrderDateBeforeFilterDate
        })
    },
    visibleOrders () {
      const startOrdersIndex = this.currentPage * this.limit
      const endOrdersIndex = startOrdersIndex + this.limit

      return this.filtredOrders.slice(startOrdersIndex, endOrdersIndex)
    },
  },
  methods: {
    ...mapActions(['getParentOrders']),
    pageChange (page) {
      this.currentPage = page - 1
    },
    changeLimit (choosenLimitItem) {
      if (!choosenLimitItem) {
        this.limit = 5
        return
      }

      this.limit = choosenLimitItem.id
      this.currentPage = 0
    },
    changeStatusFilter (choosenStatusItem) {
      if (!choosenStatusItem) {
        this.filters.status = {
          id: null,
          name: '',
        }
        return
      }

      this.filters.status = {
        id: choosenStatusItem.id,
        name: choosenStatusItem.name,
      }
      this.currentPage = 0
    },
    changeTeacherFilter (choosingTeacherItem) {
      if (!choosingTeacherItem) {
        this.filters.teacher = {
          id: null,
          name: '',
        }
        return
      }

      this.filters.teacher = {
        id: choosingTeacherItem.id,
        name: choosingTeacherItem.name,
      }
      this.currentPage = 0
    },
    changeSubjectFilter (choosingSubjectItem) {
      if (!choosingSubjectItem) {
        this.filters.subject = {
          id: null,
          name: '',
        }

        return
      }

      this.filters.subject = {
        id: choosingSubjectItem.id,
        name: choosingSubjectItem.name,
      }
      this.currentPage = 0
    }
  },
  async mounted () {
    if (this.parentId) {
      await this.getParentOrders(
        { filter: { customerId: this.parentId, }, }
      )
    }
  },
})
</script>


<style scoped lang="scss">
@import '@/assets/styles/colors.scss';
@import '@/widgets/order-story/styles/typography.scss';

.order-story {
  display: flex;
  flex-direction: column;
  gap: 24px;
  &__title {
    color: $dark-greenish-yellow-green;
    @include title-text;
  }
  &__filtering {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  &__filtering-right {
    display: flex;
    gap: 16px;
  }
  &__date-picker {
    max-width: 210px;
    height: max-content;
    ::v-deep .mx-input {
      height: 53px;
      border: none;
      outline: none;
      border-radius: 8px;
      background-color: $light-200;
      box-shadow: none;
    }
  }
  &__filter,
  &__filter-small {
    width: 210px;
    border-radius: 8px;
    background-color: $light-200;
    ::v-deep & #main-content {
      padding: 16px;
    }
    ::v-deep & #dropdown {
      top: 3.7rem;
      border-radius: 8px;
      background-color: $light-200;
      & > div {
        &:hover {
          color: $primary-200;
          background-color: $primary-100;
        }
      }
    }
  }
  &__filter-small {
    width: 150px;
    border-radius: 4px;
    ::v-deep & #main-content {
      padding: 8px;
    }
    ::v-deep & #dropdown {
      top: 2.7rem;
      border-radius: 4px;
    }
  }
  &__control {
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    height: 180px;
  }
  &__pagination {
    display: flex;
    gap: 8px;
    ::v-deep & > li {
      position: relative;
      display: flex;
      justify-content: center;
      align-items: center;
      width: 32px;
      height: 32px;
      border: 1.5px solid $primary-100;
      border-radius: 8px;
      & a {
        transition: .2s all ease;
        color: $primary-600;
        @include base-text;
        &::after {
          content: '';
          position: absolute;
          inset: 0;
        }
      }
      &:hover {
        & a {
         color: $primary-200;
        }
      }
    }
    ::v-deep & > li.active {
      background-color: $primary-100;
    }
    ::v-deep & li:first-child,
    ::v-deep & li:last-child {
      display: none;
    }
  }
  &__limit {
    display: flex;
    align-items: center;
    gap: 18px;
  }
  &__limit-text {
    color: $black;
    @include base-text;
  }
}
</style>
