<template>
  <div class="order-id">
    {{ valueId }}
  </div>
</template>
<script>
import { defineComponent } from 'vue'

export default defineComponent({
  name: 'OrderId',
  props: {
    valueId: {
      type: Number,
      default: 0,
    },
  },
})
</script>

<style scoped lang="scss">
@import '@/assets/styles/colors.scss';
@import '@/widgets/order-story/styles/typography.scss';

.order-id {
  color: $dark-400;
  @include base-text;
}
</style>
