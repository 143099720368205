<template>
  <div class="order-details">
    <div class="order-details__subject">
      {{ subject }}
    </div>

    <div class="order-details__info">
      <div class="order-details__grade">
        {{ grade }}
      </div>

      <div class="order-details__divider">
        /
      </div>

      <div class="order-details__lesson-type">
        {{ lessonType }}
      </div>
    </div>
  </div>
</template>

<script>
import { defineComponent } from 'vue'

export default defineComponent({
  name: 'OrderDetails',
  props: {
    subjectDetails: {
      type: Object,
      default: () => ({}),
    },
    orderAttributes: {
      type: Object,
      default: () => ({}),
    },
  },
  computed: {
    subject () {
      return this.subjectDetails?.name ?? '-'
    },
    grade () {
      return this.orderAttributes?.studentGrade ?? '-' + ' ' + 'класс'
    },
    lessonType () {
      return this.orderAttributes?.lessonType?.name ?? '-'
    },
  },
})
</script>

<style scoped lang="scss">
@import '@/assets/styles/colors.scss';
@import '@/widgets/order-story/styles/typography.scss';

.order-details {
  display: flex;
  flex-direction: column;
  gap: 4px;
  &__info {
    display: flex;
    align-items: center;
    gap: 8px;
  }
  &__subject,
  &__grade,
  &__divider,
  &__lesson-type {
    color: $dark-400;
    @include base-text;
  }
  &__grade,
  &__lesson-type {
    color: $dark-100;
  }
  &__divider {
    color: $light-400;
  }
}
</style>
