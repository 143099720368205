<template>
  <div class="order-duration">
    <div class="order-duration__start">
      {{ startTime }}
    </div>

    <div class="order-duration__divider">
      /
    </div>

    <div class="order-duration__end">
      {{ endTime }}
    </div>
  </div>
</template>

<script>
import { defineComponent } from 'vue'
import Moment from 'moment'

export default defineComponent({
  name: 'OrderDuration',
  props: {
    order: {
      type: Object,
      default: () => ({}),
    },
  },
  computed: {
    startTime () {
      return this.getTime(0, this.order)
    },
    endTime () {
      return this.getTime(this.order.duration.duration * 60)
    },
  },
  methods: {
    getTime (seconds = 0, order) {
      if (!seconds && order?.chat?.end) {
        seconds = Moment(order?.chat?.end).diff(Moment(order?.chat?.start), 's')
      }

      return Moment.utc(seconds * 1000).format('HH:mm:ss')
    },
  },
})
</script>

<style scoped lang="scss">
@import '@/assets/styles/colors.scss';
@import '@/widgets/order-story/styles/typography.scss';

.order-duration {
  display: flex;
  gap: 4px;
  &__start,
  &__end,
  &__divider {
    color: $dark-400;
    @include base-text;
  }
  &__divider {
    color: $light-400;
  }
}
</style>
